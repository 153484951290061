<template>
    <lazy-component style="margin-bottom:10px">
        <div style="display:flex;position: relative;">
            <div class="planPreviewerPrev">
                <a-icon type="left" :style="{ fontSize: '40px' }" @click="prevSlide"/>
            </div>
            <div class="imageViewerDiv">
                <img style="object-fit: contain;width:100%;height:100%;" :src="selectedImage != ''? `${selectedImage}`:''" >
            </div>
            <div class="planPreviewerNext">
                <a-icon type="right" :style="{ fontSize: '40px' }" @click="nextSlide"/>
            </div>
        </div>
        <div style="text-align: center;user-select: none;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;">
            {{selectedImageIdx + 1}}/{{imageData.length}}
        </div>
        <!-- <div style="position:relative;height:80px">
            
            <VueSlickCarousel 
                @afterChange="afterPageChange"
                style="width:240px;margin-left:auto;margin-right:auto"
                :dots="false"
                :infinite="true"
                :initialSlide="sliderStartIndex"
                :speed="500"
                :slidesToShow="imageData.length > 3 ? 3 : imageData.length"
                :slidesToScroll="1"
                :swipeToSlide="true"
                :arrows="false"
                ref="carouselmobile">
                <div v-for="(item, index) in imageData" :key="item" style="width:80px;height:80px">
                    <img class="slideImageHomePreviewer2" v-bind:style="[index != selectedImageIdx ? {opacity:0.6} : {opacity:1}]" 
                        :src="item != ''? `${item}`:''" draggable="false"  @click="currentSlide(index)">
                </div>
            </VueSlickCarousel>
        </div> -->
        <!-- <VueSlickCarousel 
            :dots="true"
            :dotsClass="'slick-dots custom-dot-class'"
            :infinite="true"
            :initialSlide="2"
            :speed="500"
            :slidesToShow="1"
            :slidesToScroll="1"
            :arrows="true">
            <div v-for="(item) in imageData" :key="item" >
                <img style="max-height:100px;display: block;" width="100%" height="100%" :src="item != ''? `${item}`:''" draggable="false">
                
            </div>
            
        </VueSlickCarousel> -->
    </lazy-component>
</template>
<script>

// import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    
    data() {
        return {
            selectedImage:'',
            selectedImageIdx:0,
            isDisablePrev:false,
            isDisableNext:false,
            sliderStartIndex:0,
        };
    },
    props: {
        imageIndex: Number,
        imageData: [],
    },
    components: {
        // carousel,
        // VueSlickCarousel
    },
    mounted(){
        if (this.imageData.length > this.imageIndex){
            this.selectedImage = this.imageData[this.imageIndex]
            this.selectedImageIdx = this.imageIndex;
        }
        else{
            this.selectedImage = this.imageData[0];
            this.selectedImageIdx = 0;
        }
        this.updatePrevNext();
    },
    methods: {
        afterPageChange(page) {
            this.sliderStartIndex = page;
        },
        currentSlide(n) {
            this.selectedImage = this.imageData[n];
            this.selectedImageIdx = n;
            this.updatePrevNext();
        },
        prevSlide(){
            if (this.selectedImageIdx > 0){
                this.selectedImage = this.imageData[this.selectedImageIdx -1];
                this.selectedImageIdx = this.selectedImageIdx - 1;
            }
            else{
                this.selectedImage = this.imageData[this.imageData.length -1];
                this.selectedImageIdx = this.imageData.length -1;
            }
            this.updatePrevNext();
            
            const countCell = 5
            // if (this.selectedImageIdx < this.sliderStartIndex)
            //     this.sliderStartIndex = this.selectedImageIdx;
            // else if(this.selectedImageIdx >= this.sliderStartIndex + countCell)
                this.sliderStartIndex = this.selectedImageIdx - countCell + 1 + 2;

            // this.$refs.carousel.prev()
            // this.$refs.carouselmobile.prev()
        },
        nextSlide(){
            if (this.selectedImageIdx < this.imageData.length - 1){
                this.selectedImage = this.imageData[this.selectedImageIdx + 1];
                this.selectedImageIdx = this.selectedImageIdx + 1;
            }
            else{
                this.selectedImage = this.imageData[0];
                this.selectedImageIdx = 0;
            }
            this.updatePrevNext();
            // const countCell = 5
            // if (this.selectedImageIdx >= this.sliderStartIndex + countCell)
            //     this.sliderStartIndex = this.selectedImageIdx - countCell + 1;
            // else if(this.selectedImageIdx < this.sliderStartIndex)
                this.sliderStartIndex = this.selectedImageIdx - 2;

            // this.$refs.carousel.next()
            // this.$refs.carouselmobile.next()
        },
        updatePrevNext(){
            this.isDisablePrev = (this.selectedImageIdx <= 0);
            this.isDisableNext = (this.selectedImageIdx >= this.imageData.length - 1);
        }
        
    },
}
</script>
<style scoped>
    .slideImageHomePreviewer{
        display: block;
        width:100px;
        height:100px;
        object-fit: cover;
    }
    
    
    .slideImageHomePreviewer2{
        display: block;
        width:80px;
        height:80px;
        object-fit: cover;
    }
</style>