<template class="containerImageViwertem">
    <lazy-component class="containerImageViwer">

        <!-- Full-width images with number text -->
        <div v-if="$mq === 'lg' || $mq === 'md'" class="imageViwerMain">
            <div class="imageViewerDiv">
                <img class="imageViewerImg" :src="selectedImage != ''? `${selectedImage}`:''" @click="clickMainImage" >
                
                <div v-if="!showBack" class="imageViewerImgDiv"></div>
                
                <div class="imageViewerIconDiv">
                    <a-icon v-if="isShowPrev" class="slideBtn" type="left" :style="{ fontSize: '50px',color:'white' }" @click="prevSlide"/>
                </div>
                
                <div class="imageViewerIconDiv1">
                    <a-icon v-if="isShowNext" class="slideBtn" type="right" :style="{ fontSize: '50px',color:'white'  }" @click="nextSlide"/>
                </div>
            </div>
        
            <div class="imageViewerDiv1">
                <!-- <carousel :per-page="4" :adjustableHeight="true" :autoplay="false" :nav="false" :dots="false" :rewind="true">
                    <template slot="prev"><span class="prev"><a-icon type="left" /></span></template>
                    
                    <img v-for="(item, index) in imageData" :key="item" class="demo cursor" style="max-height:100px" width="100%" height="100%" :src="item != ''? `${item}`:''" v-on:click="currentSlide(index)" alt="The Woods">

                    <template slot="next"><span class="next"><a-icon type="right" /></span></template>
                </carousel> -->
                
                <VueSlickCarousel   
                    @afterChange="afterPageChange"
                    :dots="false"
                    :infinite="true"
                    :initialSlide="sliderStartIndex"
                    :speed="500"
                    :slidesToShow="5"
                    :slidesToScroll="1"
                    :swipeToSlide="true"
                    ref="carousel">
                    <div v-for="(item, index) in imageData" :key="item">
                        <div class="slideImageHomeDiv">
                            <img class="slideImageHome" v-bind:style="[index != selectedImageIndex ? {opacity:1} : {opacity:1}]"
                            :src="item != ''? `${item}`:''" draggable="false"  @click="currentSlide(index)">
                        </div>
                    </div>
                    
                </VueSlickCarousel>
            </div>
        </div>
        <div v-else class="imageViwerMain">
            <div class="imageViewerDiv">
                <!-- <img style="object-fit: cover;width:100%;height:100%;border-top-left-radius: 12px;border-top-right-radius: 12px;" :src="selectedImage != ''? `${selectedImage}`:''" @click="clickMainImage" > -->
                <VueSlickCarousel   
                    @afterChange="afterPageChangeMobile"
                    :dots="false"
                    :infinite="true"
                    :initialSlide="selectedImageIndex"
                    :speed="500"
                    :slidesToShow="1"
                    :slidesToScroll="1"
                    :swipeToSlide="true"
                    ref="carousel">
                    <div v-for="(item) in imageData" :key="item">
                        <div class="slideImageHomeDivMobile">
                            <img class="slideImageHomeMobile"
                            :src="item != ''? `${item}`:''" draggable="false" @click="clickMainImage">
                        </div>
                    </div>
                    
                </VueSlickCarousel>
                <div v-if="!showBack" class="imageViewerImgDiv"></div>
                
                <!-- <div v-if="false" style="width:57px;height:100%;display:flex;position:absolute;top:0">
                    <a-icon v-if="isShowPrev" class="slideBtn" type="left" :style="{ fontSize: '50px',color:'white' }" @click="prevSlide"/>
                </div>
                
                <div v-if="false" style="width:57px;height:100%;display:flex;position:absolute;top:0;right:5px;">
                    <a-icon v-if="isShowNext" class="slideBtn" type="right" :style="{ fontSize: '50px',color:'white'  }" @click="nextSlide"/>
                </div> -->
            </div>
        
            <div class="imageViewerDiv2">
                <VueSlickCarousel 
                    @afterChange="afterPageChange"
                    :dots="false"
                    :infinite="true"
                    :initialSlide="sliderStartIndex"
                    :speed="500"
                    :slidesToShow="5"
                    :slidesToScroll="1"
                    :swipeToSlide="true"
                    ref="carouselmobile">
                    <div v-for="(item, index) in imageData" :key="item">
                        <div v-if="index == selectedImageIndex" class="dotSelected" 
                            @click="currentSlide(index)"></div>
                        <div v-else class="dotNormal" 
                            @click="currentSlide(index)"></div>
                        <!-- <div class="demo cursor" style="max-height:100px;display: block;"> -->
                            <!-- <img class="slideImageHome2"  v-bind:style="[index != selectedImageIndex ? {opacity:1} : {opacity:1}]"
                            :src="item != ''? `${item}`:''" draggable="false"  @click="currentSlide(index)"> -->
                        <!-- </div> -->
                    </div>
                    
                </VueSlickCarousel>
            </div>
        

        </div>
    </lazy-component>

</template>
<script>
// import carousel from 'vue-owl-carousel'
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
var selectedImage = '';
export default{
    data() {
        return {
            selectedImage,
            selectedImageIndex:0,
            isShowPrev:false,
            isShowNext:false,
            sliderStartIndex:0,
        };
    },
    
    props: {
        imageData: [],
        
        showBack: {
            type: Boolean,
            default: function () {
                return false
            }
        }
        
    },
    components: {
        // carousel,
        VueSlickCarousel
    },
    methods: {
        afterPageChangeMobile(page) {
            this.selectedImageIndex = page;
            
            if (this.selectedImageIndex - 1 > 0){
                this.currentSlide(this.selectedImageIndex - 1);
                if (this.selectedImageIndex < this.sliderStartIndex)
                    this.sliderStartIndex = this.selectedImageIndex;
                else if(this.selectedImageIndex >= this.sliderStartIndex + 5)
                    this.sliderStartIndex = this.selectedImageIndex - 2;
            }
            
            if (this.selectedImageIndex < this.imageData.length - 1){
                this.currentSlide(this.selectedImageIndex + 1);
                if (this.selectedImageIndex >= this.sliderStartIndex + 5)
                    this.sliderStartIndex = this.selectedImageIndex - 2;
                else if(this.selectedImageIndex < this.sliderStartIndex)
                    this.sliderStartIndex = this.selectedImageIndex;
            }
        },
        afterPageChange(page) {
            this.sliderStartIndex = page;
        },
        currentSlide(n) {
            console.log(n)
            
            if (n < 1)
                this.isShowPrev = false;
            else
                this.isShowPrev = true;
            if (n >= this.imageData.length - 1)
                this.isShowNext = false;
            else
                this.isShowNext = true;

            this.selectedImage = this.imageData[n];
            this.selectedImageIndex = n;
            // this.$refs.carousel.next();
            // this.sliderStartIndex = n;
            
        },

        
        prevSlide:async function(){
            if (this.selectedImageIndex - 1 < 0)
                return;
            this.currentSlide(this.selectedImageIndex - 1);
            if (this.selectedImageIndex < this.sliderStartIndex)
                this.sliderStartIndex = this.selectedImageIndex;
            else if(this.selectedImageIndex >= this.sliderStartIndex + 5)
                this.sliderStartIndex = this.selectedImageIndex - 2;
            // this.$refs.carousel.prev()
            // this.$refs.carouselmobile.prev()
        },
        nextSlide(){
            if (this.selectedImageIndex >= this.imageData.length - 1)
                return;
            this.currentSlide(this.selectedImageIndex + 1);
            if (this.selectedImageIndex >= this.sliderStartIndex + 5)
                this.sliderStartIndex = this.selectedImageIndex - 2;
            else if(this.selectedImageIndex < this.sliderStartIndex)
                this.sliderStartIndex = this.selectedImageIndex;
            // this.$refs.carousel.next()
            // this.$refs.carouselmobile.next()
        },
        clickMainImage(){
            this.$emit('showImageViewer', true, this.selectedImageIndex);
        }
        
    },
    mounted() {
        this.currentSlide(0);
    }
};
</script>


<style>
    * {
    box-sizing: border-box;
    }

    /* Position the image container (needed to position the left and right arrows) */
    .container {
        position: relative;
        /* min-width: 300px;
        max-width: 500px; */
    }

    /* Hide the images by default */
    /* .mySlides {
    display: none;
    } */

    /* Add a pointer when hovering over the thumbnail images */
    .cursor {
    cursor: pointer;
    }

    /* Next & previous buttons */
    .prev,
    .next {
        position: absolute;
        cursor: pointer;
        top: 30%;
        width: auto;
        padding: 5px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 20px;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
        z-index: 1;
    }

    /* Position the "next button" to the right */
    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
        z-index: 1;
    }
    .prev {
        z-index: 2;
    }

    /* On hover, add a black background color with a little bit see-through */
    .prev:hover,
    .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }

    /* Number text (1/3 etc) */
    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    /* Container for image text */
    .caption-container {
        text-align: center;
        background-color: #222;
        padding: 2px 16px;
        color: white;
    }

    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    /* Six columns side by side */
    .column {
        float: left;
        width: 25%;
    }

    /* Add a transparency effect for thumnbail images */
    .demo {
        opacity: 0.8;
    }

    /* .active, */
    .demo:hover {
        opacity: 1;
    }

    
    .imageViewerDiv{
        position:relative;
        width:100%;
        height:calc(100% - 220px);
        /* max-height: 55vh; */
        overflow: hidden;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    @media only screen and (max-width: 1200px) {
        .imageViewerDiv {
            position:relative;
            height: 40vh;
        }
    }
    .slideBtn{
        background-color: #00000000;
        border-radius: 5px;
        padding: 5px;
        margin-top:auto;
        margin-bottom:auto
    }
    .slideBtn:hover{
        background-color: #0000008c;
        border-radius: 5px;
        padding: 5px;
    }
    
    .slideImageHomeDiv{
        padding: 5px;
        padding-top: 10px;
    }
    .slideImageHome{
        display: block;
        object-fit: cover;
        width:100%;
        height:120px;
        border-radius: 12px;
    }
    .slideImageHomeDivMobile{
        padding: 0px;
        /* padding-top: 19px; */
        height:40vh;
    }
    .slideImageHomeMobile{
        display: block;
        object-fit: cover;
        width:100%;
        height:100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    
    
    .slideImageHome2{
        display: block;
        width:100%;
        height:80px;
        object-fit: cover;
    }
    .dotSelected{
        border-radius: 50%;
        background: #00C2CB;
        background-clip: content-box;
        border: 4px solid transparent;
        box-shadow: 0 0 0 1px white;
        width: 16px;
        height: 16px;
        margin: auto;
        margin-top: 3px;
    }
    .dotNormal{
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-clip: content-box;
        box-shadow: none;
        background: #FFFFFF;
        border: 4px solid transparent;
        margin: auto;
    }
</style>